.home-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: var(--dl-color-custom-neutral-light);
}

.home-header {
    width: 100%;
    display: flex;
    z-index: 100;
    position: fixed;
    align-items: center;
    flex-direction: column;
    background-color: var(--dl-color-custom-neutral-light);
}

.home-navbar-interactive {
    color: var(--dl-color-custom-neutral-light);
    background-color: #070545;
}

.home-image {
    width: 200px;
    object-fit: cover;
}

.home-desktop-menu {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.home-burger-menu {
    display: none;
}

.home-mobile-menu1 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: none;
    padding: 32px;
    z-index: 100;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
}

.home-hero {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 96px;
    align-items: flex-start;
    justify-content: center;
}

.home-hero1 {
    padding-top: 10px;
    padding-bottom: 0;
    background-color: var(--dl-color-custom-neutral-light);
}

.home-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.home-hero-heading {
    font-family: "MonteCarlo", cursive;
    font-weight: normal;
    color: var(--dl-color-custom-primary1);
    max-width: 800px;
    font-size: 64px;
    text-align: center;
}

.home-hero-sub-heading {
    color: var(--dl-color-custom-primary1);
    font-size: 14px;
    text-align: center;
    font-family: "Montserrat", system-ui;
    font-weight: 400;
    line-height: 150%;
}

.home-footer-sub-heading {
    color: var(--dl-color-custom-primary1);
    font-size: 16px;
    text-align: center;
    font-family: "Montserrat", system-ui;
    font-weight: 400;
    line-height: 120%;
}

.home-features {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.italic {
    font-style: italic;
}

.home-features1 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    height: 600px;
    align-items: center;
    padding: var(--dl-space-space-twounits) var(--dl-space-space-sixunits);
    flex-direction: column;
    justify-content: flex-start;
}


.home-footer {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-footer1 {
    background-color: var(--dl-color-custom-neutral-light);
}

.home-container2 {
    display: flex;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--dl-color-custom-neutral-light);
}

.home-image1 {
    width: 300px;
    padding: 16px;
    object-fit: cover;
}

.home-separator {
    flex: 0 0 auto;
    width: 100%;
    height: 0;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-black);
    border-style: solid;
    border-width: 0;
    margin: var(--dl-space-space-oneandhalfunits) 0 var(--dl-space-space-twounits);
    flex-direction: row;
    border-bottom-width: 1px;
}

.home-container3 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.home-icon-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.home-icon {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-twounits);
}

.home-icon2 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-twounits);
}

.home-icon4 {
    width: 24px;
    height: 24px;
}

@media (max-width: 991px) {
    .home-hero1 {
        flex-direction: column;
    }

    .home-container1 {
        align-items: center;
        margin-right: 0;
        margin-bottom: var(--dl-space-space-twounits);
        padding-right: 0;
    }

    .home-hero-heading {
        text-align: center;
    }

    .home-hero-sub-heading {
        text-align: center;
        padding-left: var(--dl-space-space-threeunits);
        padding-right: var(--dl-space-space-threeunits);
    }
}

@media (max-width: 767px) {
    .home-navbar-interactive {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }

    .home-desktop-menu {
        display: none;
    }

    .home-burger-menu {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .home-hero1 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }

    .home-hero-sub-heading {
        padding-left: var(--dl-space-space-unit);
        padding-right: var(--dl-space-space-unit);
    }

    .home-features1 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }

    .home-footer1 {
        padding-left: var(--dl-space-space-twounits);
        padding-right: var(--dl-space-space-twounits);
    }

    .home-separator {
        margin: var(--dl-space-space-oneandhalfunits) 0;
    }

    .home-container3 {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .home-text8 {
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
}

@media (max-width: 479px) {
    .home-navbar-interactive {
        padding: var(--dl-space-space-unit);
    }

    .home-mobile-menu1 {
        padding: 16px;
    }

    .home-hero1 {
        padding: var(--dl-space-space-twounits) var(--dl-space-space-unit);
    }

    .home-container1 {
        margin-bottom: var(--dl-space-space-unit);
    }

    .home-features1 {
        padding: var(--dl-space-space-twounits) var(--dl-space-space-unit);
    }

    .home-footer1 {
        padding: var(--dl-space-space-unit);
    }

    .home-separator {
        margin-top: var(--dl-space-space-oneandhalfunits);
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }

    .home-container3 {
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
    }

    .home-text8 {
        text-align: center;
        margin-bottom: var(--dl-space-space-oneandhalfunits);
    }
}
