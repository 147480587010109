:root {
    --dl-size-size-large: 144px;
    --dl-size-size-small: 48px;
    --dl-color-gray-black: #222222;
    --dl-color-gray-white: #FAFAFA;
    --dl-size-size-medium: 96px;
    --dl-size-size-xlarge: 192px;
    --dl-size-size-xsmall: 16px;
    --dl-space-space-unit: 16px;
    --dl-size-size-xxlarge: 288px;
    --dl-size-size-maxwidth: 1400px;
    --dl-radius-radius-round: 50%;
    --dl-space-space-halfunit: 8px;
    --dl-space-space-sixunits: 96px;
    --dl-space-space-twounits: 32px;
    --dl-color-custom-primary1: #070545;
    --dl-radius-radius-radius2: 2px;
    --dl-radius-radius-radius4: 4px;
    --dl-radius-radius-radius8: 8px;
    --dl-space-space-fiveunits: 80px;
    --dl-space-space-fourunits: 64px;
    --dl-space-space-threeunits: 48px;
    --dl-color-custom-neutral-dark: #070545;
    --dl-color-custom-neutral-light: #F4F1EB;
    --dl-color-custom-secondary1: #CC8893;
    --dl-space-space-oneandhalfunits: 24px;
}


.featuresContainer {
    flex: 0 0 auto;
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-custom-secondary1);
}

.navbarContainer {
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    position: sticky;
    align-items: center;
    padding: var(--dl-space-space-oneandhalfunits) var(--dl-space-space-threeunits);
    justify-content: space-between;
    background-color: var(--dl-color-custom-neutral-light);
}

.heroContainer {
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    min-height: auto;
    align-items: center;
    flex-direction: column;
    padding: var(--dl-space-space-sixunits) var(--dl-space-space-threeunits);
    justify-content: center;
    background-color: var(--dl-color-custom-secondary1);
}

.footerContainer {
    color: var(--dl-color-custom-neutral-dark);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: var(--dl-space-space-twounits) var(--dl-space-space-threeunits);
    justify-content: space-between;
    background-color: var(--dl-color-custom-neutral-light);
}

.socialIcons {
    fill: var(--dl-color-custom-neutral-dark);
}

.heading1 {
    font-size: 48px;
    text-align: center;
    font-family: "Montserrat", system-ui;
    font-weight: 700;
    line-height: 150%;
}

.logo {
    font-size: 2em;
    font-family: "Montserrat", system-ui;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
}

.heading2 {
    font-size: 35px;
    font-family: "Montserrat", system-ui;
    font-weight: 600;
    line-height: 150%;
}

.heading3 {
    font-size: 20px;
    text-align: center;
    font-family: "Montserrat", system-ui;
    font-weight: 600;
    line-height: 150%;
}

.bodyLarge {
    font-size: 18px;
    font-family: "Montserrat", system-ui;
    font-weight: 400;
    line-height: 150%;
}

.overline {
    font-size: 12px;
    font-family: "Montserrat", system-ui;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
}

.Content {
    font-size: 16px;
    font-family: "Montserrat", system-ui;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
}

.bodySmall {
    font-size: 12px;
    font-family: "Montserrat", system-ui;
    font-weight: 400;
    line-height: 150%;
}

.Heading {
    font-size: 32px;
    font-family: "Montserrat", system-ui;
    font-weight: 700;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
}
